export default [
  [
    "BURGER",
    "BURGER",
    "https://firebasestorage.googleapis.com/v0/b/indirioo-51f39.appspot.com/o/RestaurantFıeldImages%2FburgerFri%20Jun%2009%202023%2020%3A46%3A38%20GMT%2B0300%20(GMT%2B03%3A00)?alt=media&token=2d970cb4-48d0-4d56-a0cd-d9cc941fea72&_gl=1*1eo53oi*_ga*MTQ2MTc3MjM4OC4xNjc5NDA5MjY4*_ga_CW55HF8NVT*MTY4NjMzMjQ1Ny4yNy4xLjE2ODYzMzI4MDYuMC4wLjA.",
  ],
  [
    "DONER",
    "DONER",
    "https://firebasestorage.googleapis.com/v0/b/indirioo-51f39.appspot.com/o/RestaurantFıeldImages%2Fdoner.jpgSat%20May%2020%202023%2022%3A11%3A58%20GMT%2B0300%20(GMT%2B03%3A00)?alt=media&token=54974f58-0c53-4c4b-bf73-89fdbc9edcb6",
  ],
  [
    "PIZZA",
    "PIZZA",
    "https://firebasestorage.googleapis.com/v0/b/indirioo-51f39.appspot.com/o/RestaurantFıeldImages%2Fpizza.jpgSat%20May%2020%202023%2022%3A14%3A05%20GMT%2B0300%20(GMT%2B03%3A00)?alt=media&token=043c5690-d804-4034-98dc-569b68147540",
  ],
  [
    "KEBAP-IZGARA",
    "KEBAP-IZGARA",
    "https://firebasestorage.googleapis.com/v0/b/indirioo-51f39.appspot.com/o/RestaurantFıeldImages%2Fkebap.jpegSat%20May%2020%202023%2022%3A12%3A58%20GMT%2B0300%20(GMT%2B03%3A00)?alt=media&token=426a087e-cd91-4bbf-ba77-2ed3e9b1cf3a",
  ],
  [
    "TAVUK",
    "TAVUK",
    "https://firebasestorage.googleapis.com/v0/b/indirioo-51f39.appspot.com/o/RestaurantFıeldImages%2Ftavuk.jpgSat%20May%2020%202023%2022%3A14%3A34%20GMT%2B0300%20(GMT%2B03%3A00)?alt=media&token=2139067a-753b-4360-a344-a789a6c787f9&_gl=1*17i29o7*_ga*MTQ2MTc3MjM4OC4xNjc5NDA5MjY4*_ga_CW55HF8NVT*MTY4NjM3MTc1OC4yOC4xLjE2ODYzNzE3ODEuMC4wLjA.",
  ],
  [
    "KOFTE",
    "KOFTE",
    "https://firebasestorage.googleapis.com/v0/b/indirioo-51f39.appspot.com/o/RestaurantFıeldImages%2Fkofte.jpgSat%20May%2020%202023%2022%3A13%3A06%20GMT%2B0300%20(GMT%2B03%3A00)?alt=media&token=e412fc44-66cd-4cf8-b18f-1193ae1b770c",
  ],
  [
    "PIDE-LAHMACUN",
    "PIDE-LAHMACUN",
    "https://firebasestorage.googleapis.com/v0/b/indirioo-51f39.appspot.com/o/RestaurantFıeldImages%2Flahmacun.jpgSat%20May%2020%202023%2022%3A13%3A17%20GMT%2B0300%20(GMT%2B03%3A00)?alt=media&token=1ffad2b4-c63d-4997-8936-93450a4aeef6",
  ],
  [
    "EV-YEMEKLERI",
    "EV-YEMEKLERI",
    "https://firebasestorage.googleapis.com/v0/b/indirioo-51f39.appspot.com/o/RestaurantFıeldImages%2FevYemekleri.jpgSat%20May%2020%202023%2022%3A12%3A34%20GMT%2B0300%20(GMT%2B03%3A00)?alt=media&token=241b47a2-9b9d-436a-8f4c-958f0c682f5f",
  ],
  [
    "MANTI-GOZLEME",
    "MANTI-GOZLEME",
    "https://firebasestorage.googleapis.com/v0/b/indirioo-51f39.appspot.com/o/RestaurantFıeldImages%2Fmanti.jpgSat%20May%2020%202023%2022%3A13%3A25%20GMT%2B0300%20(GMT%2B03%3A00)?alt=media&token=d43628ce-4593-4253-bb23-6488f8208c97",
  ],
  [
    "DUNYA-MUTFAGI",
    "DUNYA-MUTFAGI",
    "https://firebasestorage.googleapis.com/v0/b/indirioo-51f39.appspot.com/o/RestaurantFıeldImages%2FdunyaMutfaklari.jpgSat%20May%2020%202023%2022%3A12%3A27%20GMT%2B0300%20(GMT%2B03%3A00)?alt=media&token=9cc91d50-5a5e-4e7e-90d8-6933a3bf594c",
  ],
  [
    "TOST-SANDVIC",
    "TOST-SANDVIC",
    "https://firebasestorage.googleapis.com/v0/b/indirioo-51f39.appspot.com/o/RestaurantFıeldImages%2Ftost.jpgSat%20May%2020%202023%2022%3A14%3A42%20GMT%2B0300%20(GMT%2B03%3A00)?alt=media&token=4acbaf52-1d3a-4477-9099-ec5e472c8d7a",
  ],
  [
    "TANTUNI",
    "TANTUNI",
    "https://firebasestorage.googleapis.com/v0/b/indirioo-51f39.appspot.com/o/RestaurantFıeldImages%2Ftantuni.jpgSat%20May%2020%202023%2022%3A14%3A27%20GMT%2B0300%20(GMT%2B03%3A00)?alt=media&token=c32cbb1c-4028-4d30-b447-3d06857a8abf",
  ],
  [
    "PILAV-MAKARNA",
    "PILAV-MAKARNA",
    "https://firebasestorage.googleapis.com/v0/b/indirioo-51f39.appspot.com/o/RestaurantFıeldImages%2Fpilav.jpgSat%20May%2020%202023%2022%3A13%3A53%20GMT%2B0300%20(GMT%2B03%3A00)?alt=media&token=ec98d417-e059-4256-aae3-c6c285aac9bc",
  ],
  [
    "CIGKOFTE",
    "CIGKOFTE",
    "https://firebasestorage.googleapis.com/v0/b/indirioo-51f39.appspot.com/o/RestaurantFıeldImages%2Fcigkofte.jpgSat%20May%2020%202023%2022%3A11%3A27%20GMT%2B0300%20(GMT%2B03%3A00)?alt=media&token=32ee5764-3e16-4591-b3a2-ac70b6346301",
  ],
  [
    "MEZE",
    "MEZE",
    "https://firebasestorage.googleapis.com/v0/b/indirioo-51f39.appspot.com/o/RestaurantFıeldImages%2Fmeze.jpgSat%20May%2020%202023%2022%3A13%3A35%20GMT%2B0300%20(GMT%2B03%3A00)?alt=media&token=f6131ea7-86d6-4d50-b4d4-802a34b732f9",
  ],
  [
    "VEJETERYAN",
    "VEJETERYAN",
    "https://firebasestorage.googleapis.com/v0/b/indirioo-51f39.appspot.com/o/RestaurantFıeldImages%2Fvejeteryan.jpgSat%20May%2020%202023%2022%3A14%3A50%20GMT%2B0300%20(GMT%2B03%3A00)?alt=media&token=4dde96d7-ac93-4e6f-8f25-129dacfa09e8",
  ],
  [
    "SALATA",
    "SALATA",
    "https://firebasestorage.googleapis.com/v0/b/indirioo-51f39.appspot.com/o/RestaurantFıeldImages%2Fsalata.jpgSat%20May%2020%202023%2022%3A14%3A12%20GMT%2B0300%20(GMT%2B03%3A00)?alt=media&token=2941f08c-5720-4a54-8a71-21a3a9aae2e0",
  ],
  [
    "KUMPIR-WAFFLE",
    "KUMPIR-WAFFLE",
    "https://firebasestorage.googleapis.com/v0/b/indirioo-51f39.appspot.com/o/RestaurantFıeldImages%2FkumpirFri%20Jun%2009%202023%2020%3A53%3A24%20GMT%2B0300%20(GMT%2B03%3A00)?alt=media&token=6a72fec1-3ca0-445a-8d6a-4d2e2cb5834a&_gl=1*1dkg7g8*_ga*MTQ2MTc3MjM4OC4xNjc5NDA5MjY4*_ga_CW55HF8NVT*MTY4NjMzMjQ1Ny4yNy4xLjE2ODYzMzMyMTYuMC4wLjA.",
  ],
  [
    "SOKAK-LEZZETLERI",
    "SOKAK-LEZZETLERI",
    "https://firebasestorage.googleapis.com/v0/b/indirioo-51f39.appspot.com/o/RestaurantFıeldImages%2FsokakFri%20Jun%2009%202023%2020%3A49%3A16%20GMT%2B0300%20(GMT%2B03%3A00)?alt=media&token=2f1da8ea-027b-4a87-af1b-111efad35ecd&_gl=1*9geutg*_ga*MTQ2MTc3MjM4OC4xNjc5NDA5MjY4*_ga_CW55HF8NVT*MTY4NjMzMjQ1Ny4yNy4xLjE2ODYzMzI5NzAuMC4wLjA.",
  ],
  [
    "DENIZ-URUNLERI",
    "DENIZ-URUNLERI",
    "https://firebasestorage.googleapis.com/v0/b/indirioo-51f39.appspot.com/o/RestaurantFıeldImages%2Fbalik.jpgSat%20May%2020%202023%2022%3A11%3A03%20GMT%2B0300%20(GMT%2B03%3A00)?alt=media&token=b0759470-9674-459e-94f2-ed32fab31036",
  ],
  [
    "KAHVALTI",
    "KAHVALTI",
    "https://firebasestorage.googleapis.com/v0/b/indirioo-51f39.appspot.com/o/RestaurantFıeldImages%2Fkahvalti.jpgSat%20May%2020%202023%2022%3A12%3A41%20GMT%2B0300%20(GMT%2B03%3A00)?alt=media&token=ae36ad1b-0406-4705-b8c8-38781e1778bb&_gl=1*1k46uys*_ga*MTQ2MTc3MjM4OC4xNjc5NDA5MjY4*_ga_CW55HF8NVT*MTY4NjQwMjMxOS4zMC4xLjE2ODY0MDMzOTUuMC4wLjA.",
  ],
  [
    "PASTANE-FIRIN",
    "PASTANE-FIRIN",
    "https://firebasestorage.googleapis.com/v0/b/indirioo-51f39.appspot.com/o/RestaurantFıeldImages%2Fpastane.jpgSat%20May%2020%202023%2022%3A13%3A45%20GMT%2B0300%20(GMT%2B03%3A00)?alt=media&token=b80f55d2-4ff0-47b1-a17d-67421fce50db",
  ],
  [
    "DONDURMA",
    "DONDURMA",
    "https://firebasestorage.googleapis.com/v0/b/indirioo-51f39.appspot.com/o/RestaurantFıeldImages%2Fdondurma.jpgSat%20May%2020%202023%2022%3A11%3A36%20GMT%2B0300%20(GMT%2B03%3A00)?alt=media&token=82509d3f-e02f-406b-9056-175bd97759ed",
  ],
  [
    "TATLI",
    "TATLI",
    "https://firebasestorage.googleapis.com/v0/b/indirioo-51f39.appspot.com/o/RestaurantFıeldImages%2Fbaklava.jpgSat%20May%2020%202023%2022%3A10%3A29%20GMT%2B0300%20(GMT%2B03%3A00)?alt=media&token=0bb15652-e91d-4d5b-9d02-5fd93edc45ca",
  ],
  [
    "KAHVE",
    "KAHVE",
    "https://firebasestorage.googleapis.com/v0/b/indirioo-51f39.appspot.com/o/RestaurantFıeldImages%2Fkahve.jpgSat%20May%2020%202023%2022%3A12%3A49%20GMT%2B0300%20(GMT%2B03%3A00)?alt=media&token=9c65639a-1268-477b-a368-832b5489aae1",
  ],
];
