import { useState } from "react";
import Navbar from "../Layout/Navbar";
import Restaurants from "./Restaurants";
import Map from "./Map";
import { QueryContext } from "../../Context/QueryContext";
import { Container } from "@mui/material";
import { useEffect } from "react";
function Main() {
  const [queryData, setQueryData] = useState("DONER");
  const [currentLocation, setCurrentLocation] = useState(null)
  
  useEffect(() => {
    if(navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        if(position) {
          var userPosition = new window.google.maps.LatLng(parseFloat(position.coords.latitude),parseFloat(position.coords.longitude));
          setCurrentLocation(userPosition)
        } else {
          console.log("Position is nul")
        }
      
      })
    } else {
      console.log("Geolocation not usable");      
    }
   
  }, []);



  return (
    <div>
      <Navbar />
      <QueryContext.Provider value={{ queryData, setQueryData }}>
        <Container>
          <Map currentLocation={currentLocation}/>
        </Container>
        <Restaurants currentLocation={currentLocation}/>
      </QueryContext.Provider>
    </div>
  );
}

export default Main;
