import React, { useState, useEffect, useContext } from "react";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import { query, where, getDocs, doc } from "firebase/firestore";
import { db } from "../../firebase";
import { collection, onSnapshot } from "firebase/firestore";
import { getDistance } from "geolib";
import { QueryContext } from "../../Context/QueryContext";
import MarkerIcon from "../../Assets/MarkerIcon/MarkerIcon.png";
import { useNavigate } from "react-router-dom";

const containerStyle = {
  marginTop: "100px",
  width: "100%",
  height: "400px",
};

const options = {
  disableDefaultUI: true,
  zoomControl: true,
};

function MyMap({currentLocation}) {
  const navigate = useNavigate();
  const [isLoaded, setIsLoaded] = useState(false);
  const queryContext = useContext(QueryContext);
  const queryData = queryContext && queryContext.queryData;
  const setQueryData = queryContext && queryContext.setQueryData;
  
  const [data, setData] = useState([]);



  useEffect(() => {
    
    if (currentLocation !== null) {
      const colRef = collection(db, "Restaurants");
      const stateQuery = query(
        colRef,
        where("isAccepted", "==", true),
        where("field", "==", queryData)
      );

      getDocs(stateQuery)
        .then((querySnapshot) => {
          console.log("QUERRY");
          const docs = [];
          querySnapshot.forEach((doc) => {
            console.log(doc.data())
            if (doc.data().lat && doc.data().lng) {
              console.log(currentLocation.lat())
              const distance = getDistance(
                {
                  latitude: currentLocation.lat(),
                  longitude: currentLocation.lng(),
                },
                { latitude: doc.data().lat, longitude: doc.data().lng }
              );
              console.log("DİR");
              console.log(distance)
              docs.push({ ...doc.data(), id: doc.id });
            }
          });
          console.log(docs)
          
          setData(docs);
        })
        .catch((error) => {
          console.log("Error getting documents: ", error);
        });
    }
  }, [currentLocation, queryData]);

  return (
    
    <GoogleMap
    mapContainerStyle={containerStyle}
    center={currentLocation}
    zoom={16}
    options={options}
  >
    {(
      currentLocation !== null ?
      <Marker
        position={currentLocation}
        icon={{
          url: MarkerIcon,
          scaledSize: new window.google.maps.Size(40, 40),
        }}
      /> : null
    )}
    {data.map((restaurant, idx) => {
      console.log(restaurant)
      return  <Marker
      onClick={() => {
        navigate(`/Restaurant/${restaurant.id}`);
      }}
      key={idx}
      position={new window.google.maps.LatLng(parseFloat(restaurant.lat),parseFloat(restaurant.lng))}
    />
    })}
  </GoogleMap>
  );
}

export default MyMap;
